import axios from "axios";
import envBase from "./envService";

const defineEnv = () => {
    return envBase;
};

const baseApi = axios.create({
    baseURL: defineEnv(),
});

export default baseApi;